.cardstyle{
    width: 18rem;
    max-height: 360px;

}

.card-body > div {
    width: 100%;
}

.card-img-top{
    height: 140px;
    object-fit: fill;
}

.Price{
    display: inline;
    font-size: large;
    height: 100%;
}
